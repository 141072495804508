<template>
  <div>
    <div class="banner">
     
      <h3>公司资质</h3>
    </div>
    <div class="energy-wrap aiService-wrap">
      <div
        class="energy-title fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        <img class='aiOPS_bjimg' src="../assets/img/qualification_8.png" alt="" />
      </div>
      <div
        class="energy-subTitle fadeOutUp energy-h2"
        ref="animate2"
        :class="{ fadeInUp: animateData.animate2 }"
        style="transition-delay: 0.2s"
      >
        公司资质证书
      </div>
      <div class='img-box l-between l-wrap'>
          <div class='block fadeOutUp'  v-for='(itm,index) in imgList' :key='itm.title'
          
          :class='[{ fadeInUp: animateData[itm.animate] },clsFn(index)]'  
          :ref="itm.animate"
          :style="{
            transitionDelay: itm.delay + 's',
          }"
          >
              <div class='imgd'>
                <img :src='itm.url' style="width:230px">

              </div>
              <h3>{{itm.title}}</h3>
          </div>
      </div>
      
    
    </div>
    <div class="qualification-title">
      <span class="fadeOutUp" ref="animate" :class="{ fadeInUp: animate }"
        >软件著作权</span
      >
    </div>
    <div class="swiper-container" ref="mySwiper" v-if='!isIE'>
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in softList" :key="item">
          <img :src="item" alt="" />
        </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination"></div>
      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev" @click="prevHandle">
        <img
          class="scenes-arrow-left"
          src="../assets/images/scenes-arrow.png"
          alt=""
        />
      </div>
      <div class="swiper-button-next" @click="nextHandle">
        <img
          class="scenes-arrow-left"
          src="../assets/images/scenes-arrow.png"
          alt=""
        />
      </div>
    </div>
    <div v-if='isIE' class='ieimgbox l-between l-wrap'>
      <div class="ieimg fadeInUp" 
        v-for="(item,index) in softList2" :key="item"
        :ref="item.animate"
        :class="{ fadeInUp: animateData[item.animate] }"
        :style="{
            transitionDelay: item.delay + 's',
          }"
          v-show='(!iss && index>2) || (iss && index<3)'
        >
          <img :src="item.url" alt="" />
        </div>
       
    </div>
     <div v-if='iss && isIE' class='cbtn b' @click='iss=false'>查看更多</div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "../utils/index.js";

import Swiper from "swiper";
import "swiper/swiper.min.css";
export default {
  data() {
    return {
      iss:true,
      softList2: [
        {url:require("../assets/images/soft_1.png"),animate:"animate10",delay:0.1},
        {url: require("../assets/images/soft_2.png"),animate:"animate10",delay:0.1},
        {url: require("../assets/images/soft_3.png"),animate:"animate10",delay:0.1},

        {url: require("../assets/images/soft_4.png"),animate:"animate11",delay:0.2},
        {url: require("../assets/images/soft_5.png"),animate:"animate11",delay:0.2},
        {url: require("../assets/images/soft_6.png"),animate:"animate11",delay:0.2},

         {url: require("../assets/images/soft_7.png"),animate:"animate12",delay:0.3},
        {url: require("../assets/images/soft_8.png"),animate:"animate12",delay:0.3},
        {url: require("../assets/images/soft_9.png"),animate:"animate12",delay:0.3},

        {url: require("../assets/images/soft_10.png"),animate:"animate13",delay:0.4},
        {url: require("../assets/images/soft_11.png"),animate:"animate13",delay:0.4},
        {url: require("../assets/images/soft_12.png"),animate:"animate13",delay:0.4},

        {url: require("../assets/images/soft_13.png"),animate:"animate14",delay:0.5},
        {url: require("../assets/images/soft_14.png"),animate:"animate14",delay:0.5},
        {url: require("../assets/images/soft_15.png"),animate:"animate14",delay:0.5},

        {url: require("../assets/images/soft_16.png"),animate:"animate15",delay:0.6},
        {url: require("../assets/images/soft_17.png"),animate:"animate15",delay:0.6},
        {url: require("../assets/images/soft_18.png"),animate:"animate15",delay:0.6},
        
       
      ],
       softList: [
        require("../assets/images/soft_1.png"),
        require("../assets/images/soft_2.png"),
        require("../assets/images/soft_3.png"),
        require("../assets/images/soft_4.png"),
        require("../assets/images/soft_5.png"),
        require("../assets/images/soft_6.png"),
        require("../assets/images/soft_7.png"),
        require("../assets/images/soft_8.png"),
        require("../assets/images/soft_9.png"),
        require("../assets/images/soft_10.png"),
        require("../assets/images/soft_11.png"),
        require("../assets/images/soft_12.png"),
        require("../assets/images/soft_13.png"),
        require("../assets/images/soft_14.png"),
        require("../assets/images/soft_15.png"),
        require("../assets/images/soft_16.png"),
        require("../assets/images/soft_17.png"),
        require("../assets/images/soft_18.png"),
      ],
      animateData: {
        animate: false,
        animate1: false,
        animate2: false,
        animate3: false,
        animate4: false,
        animate5: false,
        animate6: false,
        animate7: false,
        animate8: false,
        animate10:false,
        animate11:false,
        animate12:false,
        animate13:false,
        animate14:false,
        animate15:false,
      },
      imgList:[
          {title:"CMMI三级资质认证证书",url:require("../assets/img/qualification_4.png"), animate: "animate5", delay: 0.1},
          {title:"国家高新技术企业",url:require("../assets/img/qualification_2.png"), animate: "animate3", delay: 0.2},
          {title:"中关村高新技术企业",url:require("../assets/img/qualification_3.png"), animate: "animate4", delay: 0.3},
          {title:"专精特新",url:require("../assets/img/qualification_9.png"), animate: "animate9", delay: 0.4},
        
          {title:"ISO9001企业质量认证体系",url:require("../assets/img/qualification_5.png"), animate: "animate6", delay: 0.5},
          {title:"ISO27001信息安全认证体系",url:require("../assets/img/qualification_6.png"), animate: "animate7", delay: 0.6},
          {title:"ISO14001环境管理认证体系",url:require("../assets/img/qualification_7.png"), animate: "animate8", delay: 0.7},
          {}
      ],
      animate: false,
      isIE:false
    };
  },
  methods: {
    clsFn(idx){
        let d= ''
        if(idx>3){
            d='block2'
        }
        return d
    },
    prevHandle() {
      this.$refs.mySwiper.swiper.slidePrev();
    },
    nextHandle() {
      this.$refs.mySwiper.swiper.slideNext();
    },
    // 滚动事件
    scrollToTop() {
      !isElementNotInViewport(this.$refs.animate)
        ? (this.animate = true)
        : (this.animate = false);
    },
     // 滚动事件
    scrollToTop2() {
      
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? (this.animateData[i] = true)
          : (this.animateData[i] = false);
        
          
      }
    },
  },
  mounted() {
    let that = this;
    this.scrollToTop();
    this.scrollToTop2();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    window.addEventListener("scroll", this.scrollToTop2);
    this.$nextTick(() => {
      var swiper = new Swiper(".swiper-container", {
        slidesPerView: 9,
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    });
     let  k = IEVersion()
    if(k!='-1'){
      let path = '/ie.css'
       var head = document.getElementsByTagName('head')[0];
         var link = document.createElement('link');
         link.href = path;
         link.rel = 'stylesheet';
         link.type = 'text/css';
         head.appendChild(link);
        that.isIE=true
    }
    function IEVersion() {
      var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串  
      var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器  
      var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器  
      var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
      if(isIE) {
          var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
          reIE.test(userAgent);
          var fIEVersion = parseFloat(RegExp["$1"]);
          if(fIEVersion == 7) {
              return 7;
          } else if(fIEVersion == 8) {
              return 8;
          } else if(fIEVersion == 9) {
              return 9;
          } else if(fIEVersion == 10) {
              return 10;
          } else {
              return 6;//IE版本<=7
          }   
      } else if(isEdge) {
          return 'edge';//edge
      } else if(isIE11) {
          return 11; //IE11  
      }else{
          return -1;//不是ie浏览器
      }
  }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang="less" scoped>
.banner {
    width:100%;
    height:348px;
    background: url(../assets/img/qualification_1.png) no-repeat center center;
    background-size:1920px 348px;
  h3{
        height: 45px;
        font-size: 34px;
        font-family: MicrosoftYaHei;
        color: #FFFFFF;
        line-height: 45px;
        width:1180px;
        margin: 0px auto;
        padding-top:120px;
        
  }
}
.qualification-title {
  height: 40px;
  width: 1180px;
  display: flex;
  align-items: center;
  margin: 68px auto 0;
  span {
    font-size: 24px;
    color: #5C5E61;
    margin: 0 15px;
  }
  &::before {
    content: "";
    flex: 1;
    height: 1px;
    background: rgba(206, 206, 206, .4);
  }
  &::after {
    content: "";
    flex: 1;
    height: 1px;
    background: rgba(206, 206, 206, .4);
  }
}
.swiper-container {
  width: 1180px;
  margin: 35px auto 66px;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.8);
  position: relative;
  img {
    width: 335px;
    // height: 460px;
  }
}
.swiper-button-prev,
.swiper-button-next {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 2;
  cursor: pointer;
}
.swiper-button-next {
  right: 0;
  transform: translate(0, -50%) rotate(180deg);
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
  z-index: 1;
}


.energy-wrap {
  background: #fff;
  padding-top: 90px;
  padding-bottom: 15px;
   .energy-bjimg{
     display: block;
      z-index: -1;
      margin:0 auto;
    }
  .energy-title {
    height: 74px;
    font-size: 28px;
    color: #414B59;
    line-height: 37px;
    text-align: center;
    position: relative;
    top:-20px;   
  }
  .energy-subTitle {
    // font-size: 18px;
    // color: #818cad;
    // line-height: 36px;
    // text-align: center;
    // position: relative;
    // left: -5px;

    width: 1139px;
    height: 96px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #909399;
    line-height: 32px;
    margin:0 auto;
    text-align: center;
  }
 
  .energy {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1180px;
    margin: 0 auto ;
    position: relative;
    top:-50px;
  }
  .energy-item {
    width: 224px;
    height: 95.5px;
    box-sizing: border-box;
    padding: 39px 0 0;
    position: relative;
    bottom: 0;
    transition: all 0.3s ease-in-out;
    &:hover {
      box-shadow: 0px 0px 10px 8px rgba(90, 90, 90, 0.08);
      border-radius: 8px;
      bottom: 6px;
    }
    .icon {
      width: 124px;
      height: 117px;
      margin: 0 auto 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    span {
      font-size: 18px;
      color: #243159;
      display: block;
      text-align: center;
    }
    .content {
      margin-top: 15px;
      font-size: 14px;
      color: #63697b;
      padding: 0 40px;
      line-height: 30px;
    }
  }
   .energy-h1{
     top:0;
    //  margin-top:102px;
  }
  .energy-h2{
      font-size: 28px ;
      font-family: MicrosoftYaHei;
      color: #193967;
      line-height: 37px;
      top:-63px;
      position: relative;

    }
}
.img-box{
    width:1180px;
    margin:0 auto;
    position: relative;
    top: -80px;
    .block{
        width: 270px;
        height: 244px;
        background: #FFFFFF;
        box-shadow: 0px 0px 11px 6px rgba(65, 65, 65, 0.04);
        border-radius: 8px;
        text-align: center;
        margin-bottom:33px;
        .imgd{

        }
        img{
            margin-top:19px;
            transition: all 0.5s ease-in;
            -webkit-transition:all 0.5s ease-in;
        }
        img:hover{
          transform:scale(1.1);
          -ms-transform:scale(1.1); 	/* IE 9 */
          -moz-transform:scale(1.1); 	/* Firefox */
          -webkit-transform:scale(1.1); /* Safari 和 Chrome */
          -o-transform:scale(1.1); 
        }
        h3{
           font-size: 16px;
            color: #909399;
            line-height: 32px; 
            margin-top:7px;
            font-weight: 400;
        }
    }
    .block2{
        width: 270px;
        height: 400px;
        background: #FFFFFF;
        box-shadow: 0px 0px 11px 6px rgba(65, 65, 65, 0.04);
        border-radius: 8px;
        img{
            margin-top:25px;
            width:200px !important;
        }
        h3{
            margin-top:27px;
        }
    }
}
.ieimgbox{
  width:1180px;
  margin:50px auto;
  
  .ieimg{
    margin-top:20px;
  }
}
.cbtn{
  width: 151px;
    height: 48px;
    background: #f6f6f6;
    border-radius: 4px;
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #666;
    text-align: center;
    line-height: 48px;
    margin: 50px auto;
}
</style>